export { HomePage } from "./home";
export { SignUp } from "./signUp";
export { SignIn } from "./signIn";
export { ResetPassword } from "./resetPassword";
export { SetPassword } from "./setPassword";
export { MerchantSignUp } from "./merchantsSignUp";
export { ForMerchants } from "./forMerchants";
export {
  ProfileAccount,
  Transactions,
  BalanceHistory,
  Wallets,
  Withdraw,
} from "./profile";
export { TermsOfUse } from "./termsOfUse";
export { PrivacyPolicy } from "./privacyPolicy";
export { FaqPage } from "./faq";
export { OngoingTrades } from "./ongoingTrades";
export { ForgotPassword } from "./forgotPassword";
