import { localizationArmenian } from "./armenian";
import { localizationEnglish } from "./english";
import { localizationRussian } from "./russian";
import { localizationChinese } from "./chinese";
import { useAppSelector } from "reduxState/store";

interface TranslationList {
  [key: string]: string;
}

const localization: {
  hy: TranslationList;
  en: TranslationList;
  ru: TranslationList;
  cn: TranslationList;
} = {
  hy: localizationArmenian,
  en: localizationEnglish,
  ru: localizationRussian,
  cn: localizationChinese,
};

export const useTranslation = () => {
  const language = useAppSelector((state) => state.main.language);

  return localization[language as keyof typeof localization];
};
