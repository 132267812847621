import { IAuthData, ICardDelete, IError } from "types";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { cardsApi } from "service";
import { setToastData } from "reduxState/features/toast";
import { TOAST_TYPES } from "constants/toast";

export const getCards = createAsyncThunk(
  "getCards",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await cardsApi.getCards();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCard = createAsyncThunk(
  "createCard",
  async ({ data, navigate }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await cardsApi.createCard(data);

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );

      dispatch(getCards());

      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteCard = createAsyncThunk(
  "deleteCard",
  async ({ id, navigate }: ICardDelete, { rejectWithValue, dispatch }) => {
    try {
      const res = await cardsApi.deleteCard(Number(id));

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );

      dispatch(getCards());

      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);
