import { createSlice } from "@reduxjs/toolkit";

import { LANGUAGE } from "constants/header";
import { IMainState } from "types";

const initialState: IMainState = {
  language: localStorage.getItem(LANGUAGE) || "en",
  showLogoutPopup: false,
  showMobileMenu: false,
  showChangePassword: false,
  showCreateTrade: false,
  showActivate: false,
  tradeActivationData: {
    id: null,
    type: "",
    tab: "",
  },
  showCloseTrade: false,
  closeTradeId: 0,
  activeTab: "",
  showRequestPopup: false,
  selectedRequestData: {
    id: "",
    isActivate: false,
    requester_id: "",
    activeTab: "",
  },
  tradeModalData: {
    title: "",
    description: "",
    showTradeModal: false,
    icon: "",
    type: "",
    id: "",
  },
  showVerifyModal: false,
  showAddToCardPopup: false,
  showDeleteCardModal: false,
  selectedCardId: null,
};

export const mainSlice = createSlice({
  name: "Main",
  initialState: initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
    setShowLogoutPopup: (state, { payload }) => {
      state.showLogoutPopup = payload;
    },
    setShowMobileMenu: (state, { payload }) => {
      state.showMobileMenu = payload;
    },
    setShowChangePassword: (state, { payload }) => {
      state.showChangePassword = payload;
    },
    setShowCreateTrade: (state, { payload }) => {
      state.showCreateTrade = payload;
    },
    setShowActivate: (state, { payload }) => {
      state.showActivate = payload.isOpen;
      state.tradeActivationData = {
        id: payload.id,
        type: payload.type,
        tab: payload.tab,
      };
    },
    setShowCloseTrade: (state, { payload }) => {
      state.showCloseTrade = payload.isOpen;
      state.closeTradeId = payload.id;
      state.activeTab = payload.tab;
    },
    setShowRequestPopup: (state, { payload }) => {
      state.showRequestPopup = payload.isOpen;
      state.selectedRequestData = payload.selectedRequestData;
    },
    setTradeModalData: (state, { payload }) => {
      state.tradeModalData = payload;
    },
    setShowVerifyModal: (state, { payload }) => {
      state.showVerifyModal = payload;
    },
    setShowAddToCardPopup: (state, { payload }) => {
      state.showAddToCardPopup = payload;
    },
    setShowDeleteCardModal: (state, { payload }) => {
      state.showDeleteCardModal = payload;
    },
    setSelectedCardId: (state, { payload }) => {
      state.selectedCardId = payload;
    },
  },
});

export const {
  setLanguage,
  setShowLogoutPopup,
  setShowMobileMenu,
  setShowChangePassword,
  setShowCreateTrade,
  setShowActivate,
  setShowCloseTrade,
  setShowRequestPopup,
  setTradeModalData,
  setShowVerifyModal,
  setShowAddToCardPopup,
  setShowDeleteCardModal,
  setSelectedCardId,
} = mainSlice.actions;

export default mainSlice.reducer;
