interface TranslationList {
  [key: string]: string;
}

export const localizationChinese: TranslationList = {
  home: "主页",
  merchants: "商户",
  faq: "常见问题",
  login: "登录",
  sign_up: "注册",
  support: "支持",
  usa: "Eng",
  rus: "Рус",
  arm: "Հայ",
  cn: "中文",
  copyright: "版权所有 ©",
  copyright_crypto: "科贝克斯",
  about: "关于",
  about_us: "关于我们",
  ongoing_trades: "进行中的交易",
  legal: "法律资讯",
  terms_of_use: "使用条款",
  privacy_policy: "隐私政策",
  contact_us: "联系我们",
  social_media: "社交媒体",
  name_input: "名字/姓氏",
  email: "电子邮件*",
  message: "留言",
  all: "全部",
  buy: "购买",
  sell: "出售",
  search: "搜索",
  filter: "筛选",
  merchant: "商家",
  currency: "货币",
  amount: "金额",
  price_rate: "价格汇率（美元）",
  banks: "银行",
  trade: "交易",
  date_of_birth: "出生日期",
  phone_number: "手机号码*",
  passport_number: "护照号码",
  passport_series: "护照序列号",
  passport_image: "护照/身份证图片*",
  passport_image_without_mandatory: "护照/身份证图片",
  password: "密码*",
  confirm_password: "确认密码*",
  info_title: "请上传您手持护照的照片。请确保",
  done: "好",
  already_have_account: "已有账户？",
  telegram_name: "Telegram 名称",
  nickname: "昵称*",
  first_name: "名字*",
  last_name: "姓氏*",
  dont_have_account: "还没有账户?",
  create_trade: "创建交易",
  my_wallet: "我的钱包",
  forgot_password: "忘记密码？",
  two_factor_auth: "双重身份验证",
  two_factor_auth_desc:
    "在您的手机上下载 Microsoft 或 Google 验证器应用并扫描此二维码。",
  cant_scan_qr: "无法扫描二维码？",
  qr_scan_desc: "如果无法扫描上述二维码，请将此代码插入您的验证器应用。",
  enter_verification_code: "输入验证码",
  account: "账户",
  wallet: "钱包",
  withdrawal: "提现",
  balance_history: "余额记录",
  transaction_history: "交易记录",
  logout: "登出",
  logout_modal_text:
    "您确定要登出您的账户吗？请确保在继续之前保存所有未保存的工作。",
  yes: "是",
  cancel: "取消",
  forgot_password_title: "忘记密码",
  forgot_pass_modal_desc:
    "输入您的电子邮件地址，我们会向您发送重置密码的说明。",
  email_address: "电子邮件地址*",
  send_instruction: "发送说明",
  new_password: "新密码*",
  success: "成功",
  error: "错误",
  info: "信息",
  warning: "警告",
  change_password: "更改密码",
  reset_password: "重置密码",
  set_password: "设置密码",
  password_info:
    "密码必须至少包含6个字符，最多20个字符，并至少包含一个大写字母、小写字母和一个数字。",
  language: "语言",
  required: "必填",
  pattern_error:
    "密码长度必须在6至20个字符之间，至少包含一个大写字母、一个小写字母和一个数字",
  okay: "确定",
  thank_you_for_register: "感谢注册",
  congrats_for_register: "恭喜您注册成功 ",
  email_check_message: "请检查您的电子邮件以验证注册",
  password_matches: "密码不匹配",
  email_pattern_error: "无效的电子邮件",
  example: "示例",
  upload_image: "上传图片或拖放",
  become_merchant: "成为商户",
  submit: "提交",
  balance: "余额",
  save_changes: "保存更改",
  inactive: "未激活",
  activated: "已激活",
  activate: "激活",
  inactivate: "停用",
  old_password: "旧密码*",
  current_password: "当前密码*",
  im_agree: "我同意",
  and: "和",
  load_more: "加载更多",
  price_rate_without: "价格",
  create: "创建",
  home_table_title: "轻松买卖加密货币",
  rows_per_page: "每页行数",
  request_trade: "请求交易",
  request_trade_text:
    "输入金额。您的请求将发送给商户。如果在10分钟内获得批准，将打开聊天窗口。否则，请求将自动失效",
  request_trade_info: "金额不能超过商户提供的数量",
  active: "活跃",
  trade_id: "交易编号",
  activate_trade: "您确定要激活交易吗？激活交易后，它将显示在交易表中",
  inactivate_trade: "您确定要停用交易吗？停用交易后，它将从交易表中移除",
  close_trade: "关闭交易",
  close_trade_text: "您确定要关闭交易吗？关闭交易后，它将从交易表中移除",
  id: "ID",
  requests: "请求",
  select_trade: "选择交易",
  select_trade_text: "从列表中选择交易以查看所有请求",
  pending: "待处理",
  requested_amount: "请求金额",
  user_equest_time: "用户请求时间",
  accept: "接受",
  accept_request: "接受请求",
  accept_request_text: "您确定要接受此请求吗？接受请求将启动与用户的聊天。",
  cancel_request: "取消请求",
  cancel_request_text: "您确定要取消此请求吗？此操作无法撤销。",
  in_progress: "进行中",
  request_id: "请求编号",
  go_to_chat: "进入聊天",
  min_amount: "最小金额",
  max_amount: "最大金额",
  arbitrage: "套利",
  confirm: "确认",
  merchant_cancel_deal: " 已取消交易",
  canceled_deal_message: "如果您也确认，聊天将关闭，交易将完成。",
  user_conform_deal: " 已确认交易",
  sender_conform_deal: "您已确认交易",
  sender_cancel_deal: "您已取消交易",
  merchant_conform_deal: " 已确认交易",
  confirm_deal_message: "如果您也确认，聊天将关闭，交易将完成。",
  cancel_trade: "取消交易",
  cancel_trade_message:
    "您确定要取消交易吗？取消交易将关闭聊天，并将交易移动到交易历史记录中。",
  initiate_arbitrage: "开始套利",
  initiate_arbitrage_message:
    "您想参与套利吗？如果选择“是”，管理员将加入聊天以协助处理",
  currencies: "货币",
  confirm_trade: "确认交易",
  confirm_trade_text: "您确定要确认交易吗？",
  closed: "已关闭",
  trade_time: "交易时间",
  deal_id: "交易编号",
  rate: "汇率",
  status: "状态",
  date: "日期",
  action: "操作",
  id_nickname: "身份/昵称",
  view_details: "查看详情",
  canceled: "已取消",
  completed: "已完成",
  source: "来源",
  type: "类型",
  outcome: "结果",
  income: "收入",
  type_message: "输入信息...",
  currency_mandatory: "货币*",
  amount_mandatory: "数量*",
  price_rate_mandatory: "价格率*",
  banks_mandatory: "银行*",
  quantity: "数量",
  please_scan_qr: "请扫描二维码或复制生成的地址以存入您的加密资产。",
  wallet_address: "钱包地址",
  withdraw: "提现",
  step: "步骤",
  step_one_desc:
    "通过我们的简化提现流程，轻松将资金从您的钱包转移到您所需的账户。",
  step_two_desc: "要开始提现流程，需要账户验证。请完成验证过程以继续您的提现。",
  withdrawal_amount_mandatory: "提现金额*",
  destination_address_mandatory: "目标地址*",
  next: "下一步",
  proceed_withdrawal: "继续提现",
  accepted: "进行中",
  rejected: "已取消",
  trade_details: "交易详情",
  no_requests: "没有请求",
  no_available_requests: "此交易没有可用请求",
  value_must_be_greater: "数值必须大于0。",
  confirm_pass: "确认密码",
  ongoing_requests: "进行中的请求",
  request: "请求",
  for_withdrawals_please: "对于提现，请",
  two_factor_authentication: "双重身份验证 (2FA)",
  two_fa_error_message: "需要双重身份验证 (2FA)。",
  empty_message_active: "未找到活跃交易。",
  empty_message_inactive: "未找到非活跃交易。",
  empty_message_accepted: "未找到进行中的请求。",
  empty_message_pending: "未找到待处理请求。",
  empty_message_trade: "没有可用交易",
  empty_message_request: "没有可用请求",
  sender_arbitraged: "你进行了套利。",
  user_arbitraged: " 进行了套利",
  arbitrage_message: "管理员已被邀请加入聊天。",
  trade_completed: "交易完成",
  trade_rate_modal_text:
    "交易已成功完成。感谢您的合作。请花一分钟时间互相评分。",
  value_must_be_greater_than: "数值必须大于或等于0.000001。",
  two_factor_disable_message:
    "要继续，请输入Google身份验证器应用程序提供的密钥。",
  address_pattern_error: "地址必须仅包含英文字母和数字。",
  no_results_match: "没有匹配的结果",
  no_item_matched: "没有匹配的项目。",
  please_try_with_other_options: "请尝试其他选项。",
  no_trades: "没有交易",
  no_available_trades: "没有可用交易。",
  no_requests_available: "没有可用请求。",
  verified: "已验证",
  usd_mandatory: "美元*",
  crypto_coin: "加密货币",
  crypto_coin_mandatory: "加密货币*",
  usd_price_rate: "美元汇率 / 1$ ~ 亚美尼亚德拉姆*",
  verify_checkbox_text: "未验证的用户也可以发送请求。",
  verify_your_account: "验证您的账户",
  verify_account_modal_message:
    "要发送此交易的请求，您应该是已验证用户。要完成此过程，请转到您的个人资料并上传护照图像。如果您已经上传了但尚未收到验证，请与我们联系以获取详细信息。",
  go_to_profile: "转到个人资料",
  one_usd_equivalent: "一美元等于",
  amd: "亚美尼亚德拉姆",
  usd: "美元",
  usd_to_amd: "美元汇率/亚美尼亚德拉姆",
  upload_image_profile: "上传护照图像",
  satisfied_customers: "满意的客户",
  platform_uptime: "平台正常运行时间",
  armenian_market: "亚美尼亚市场",
  no_transactions_found: "未找到交易",
  no_data: "没有数据",
  no_balance_history: "没有余额历史",
  my_cards: "My Cards",
  no_cards: "No Cards",
  no_cards_desc: "No added cards. Click to add new card.",
  add_card: "Add Card",
  card_number: "Card Number*",
  card_name: "Card Name*",
  card_name_text: "Enter the name as it appears on the card",
  add: "Add",
  name_surname: "NAME SURNAME",
  delete_card: "Delete Card",
  delete_card_text:
    "This action cannot be undone. If you proceed, the card will be permanently removed from your profile.",
  delete: "Delete",
  mark_offline_trade: "Make Offline Trade",
  location: "Location*",
  my_cards_mandatory: "My Cards*",
  change_card: "Change Card",
  no_card_added: "No added cards.",
  add_card_plus: "+Add Card",
  save: "Save",
  enter_valid_card: "Enter a valid card",
};
