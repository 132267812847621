import { FC } from "react";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

import { TABLE_BUY_SELL } from "constants/table";
import { BanksSection } from "./BanksSection";
import { IHomeTable } from "types";
import { useTranslation } from "localization";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { MainButton } from "components/buttons";
import { PATHNAMES, USER_TYPES } from "constants/global";
import { v4 as uuidv4 } from "uuid";
import { setShowVerifyModal } from "reduxState/features/main";
import armeniaValuta from "assets/images/armenianCurrency.svg";
import { CurrencyField } from "./CurrencyField";

import starIcon from "assets/images/starIcon.svg";

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const DesktopTable: FC<IHomeTable> = ({
  headerData,
  setShowModal,
  setTradeId,
  setTradeData,
}) => {
  const trades = useAppSelector((state) => state.trades.tradesData);
  const user = useAppSelector((state) => state.auth.user);

  const dispatch = useAppDispatch();

  const isMerchant = user?.role === USER_TYPES.merchant;

  const translation = useTranslation();
  const navigate = useNavigate();

  const handleBuySell = (
    id: number,
    priceRate: string,
    banks: string[],
    currencyName: string
  ) => {
    if (user?.role === USER_TYPES.user) {
      setShowModal(true);
      setTradeId(`${id}`);
      setTradeData({ priceRate, banks, currencyName });
    } else {
      navigate(PATHNAMES.sign_in);
    }
  };

  const handleShowModal = () => {
    if (user?.role !== USER_TYPES.user) {
      navigate(PATHNAMES.sign_in);
    } else {
      dispatch(setShowVerifyModal(true));
    }
  };

  return (
    <div className="table_container_desktop">
      <table
        cellPadding="1"
        cellSpacing="1"
        bgcolor="#20212c"
        className="table"
      >
        <thead>
          <tr>
            {headerData.map((element) => (
              <th key={element.id} className="header_element">
                {translation[element.name]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {trades?.map((trade) => (
            <tr key={uuidv4()}>
              <td className="table_body_element">
                <div className="merchant_row">
                  {!!trade?.merchant_rating && (
                    <div className="merchant_rate">
                      <img src={starIcon} alt="Star" className="star_icon" />
                      <span>{`${trade?.merchant_rating}/5`}</span>
                    </div>
                  )}
                  <span
                    className="merchant_name"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={`${trade?.firstName} ${trade?.lastName}`}
                  >
                    {`${trade?.firstName} ${trade?.lastName}`}
                  </span>
                  <Tooltip id="my-tooltip" className="custom_tooltip" />
                </div>
              </td>
              <td className="table_body_element">
                <div className="currency_row">
                  <img
                    src={`${imageUrl}${trade?.currencyIcon}`}
                    alt="Currency"
                    className="currency_icon"
                    crossOrigin="anonymous"
                  />
                  <div className="currency_title_field">
                    <span>{trade?.currencyLongTitle}</span>
                    <span className="currency_line"></span>
                    <span>{trade?.currencyTitle}</span>
                  </div>
                </div>
              </td>
              <td className="table_body_element">{trade?.amount}</td>
              <td className="table_body_element">
                <CurrencyField
                  currencyTitle={trade.currencyTitle}
                  amount={trade.amount}
                />
              </td>
              <td className="table_body_element">
                <div className="price_row">
                  <span>{`${trade?.priceRate}`}</span>
                  <img src={armeniaValuta} alt="Armenian Valuta" />
                </div>
              </td>
              <td className="table_body_element">
                <BanksSection banks={trade?.banks} />
              </td>
              {!isMerchant && (
                <td className="table_body_element">
                  <MainButton
                    name={translation[TABLE_BUY_SELL[trade?.type]]}
                    customClass={`${
                      trade?.type === TABLE_BUY_SELL.buy
                        ? "buy_button"
                        : "red_button"
                    } ${
                      user &&
                      !user?.isVerifiedByAdmin &&
                      trade?.forVerifiedUsers
                        ? "disable_state"
                        : ""
                    } capitalize`}
                    onClick={() =>
                      !user?.isVerifiedByAdmin && trade?.forVerifiedUsers
                        ? handleShowModal()
                        : handleBuySell(
                            trade?.id,
                            trade?.priceRate,
                            trade?.bankIds,
                            trade?.currencyTitle
                          )
                    }
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
