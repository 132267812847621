import { useEffect } from "react";

import { useTranslation } from "localization";
import { setShowAddToCardPopup } from "reduxState/features/main";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { MainButton } from "components/buttons";
import { getCards } from "reduxState";
import { SingleCard } from "components";

export const CardsSection = () => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const cards = useAppSelector((state) => state.cardsData.cards);

  const handleAddCard = () => {
    dispatch(setShowAddToCardPopup(true));
  };

  useEffect(() => {
    dispatch(getCards());
  }, []);

  return (
    <div className="cards_section">
      <div className="cards_grid">
        {cards.map((card) => (
          <SingleCard
            key={card.id}
            cardHolder={card.cardHolder}
            cardNumber={card.cardNumber}
            icon={card.image}
            id={card.id}
          />
        ))}
      </div>
      <MainButton
        name={translation.add_card}
        customClass="custom_button width_fit"
        onClick={handleAddCard}
      />
    </div>
  );
};
