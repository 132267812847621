import { createSlice } from "@reduxjs/toolkit";

import { ICardsSlice } from "types";
import { getCards, createCard } from "reduxState";

const initialState: ICardsSlice = {
  cards: [],
  loading: false,
  total: null,
};

const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCards.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCards.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cards = payload?.data?.data;
      })
      .addCase(getCards.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(createCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCard.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCard.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default cardsSlice.reducer;
