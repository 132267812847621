import { FC, useState } from "react";

import { TableBody, TableFilters, TableHeader } from "./components";
import { IMainTable, ITableFilters } from "types";
import { HOME_PAGE_TABS, HOME_PAGE_TABS_BASIC } from "constants/table";

export const MainTable: FC<IMainTable> = ({ headerData, isMerchant }) => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<ITableFilters>({});
  const [refresh, setRefresh] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(
    isMerchant ? HOME_PAGE_TABS[0].value : HOME_PAGE_TABS_BASIC[0].value
  );

  return (
    <div className="main_table_container" id="home_page_table">
      <div className="main_table_content">
        <TableHeader
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
          refresh={refresh}
          setRefresh={setRefresh}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isMerchant={isMerchant}
        />
        <div
          className={`filters_container ${
            openFilters ? "filter_container_show" : ""
          }`}
        >
          <TableFilters setFilters={setFilters} openFilters={openFilters} />
        </div>
        <TableBody
          headerData={headerData}
          filters={filters}
          refresh={refresh}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
};
